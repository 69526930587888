import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { AiOutlineInstagram } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { Wrapper } from './styles';

export interface TeamCardProps {
  imgPath: string;
  isBlog: boolean;
  title: string;
  description: string;
  ghLink: string;
  demoLink?: string;
}

export function TeamCard(props: TeamCardProps) {
  return (
    <Wrapper className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: 'justify', lineHeight: '22px' }}>
          {props.description}
        </Card.Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
          }}
        >
          <Button variant="primary" href={props.ghLink} target="_blank">
            <AiFillLinkedin /> &nbsp;
            {props.isBlog ? 'Blog' : 'linkedin'}
          </Button>
        </div>
        {'\n'}
        {'\n'}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: '10px' }}
          >
            <AiOutlineInstagram /> &nbsp;
            {'Instagram'}
          </Button>
        )}
      </Card.Body>
    </Wrapper>
  );
}
