import { Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineDownload } from 'react-icons/ai';
import { Footer } from '../../components/Footer';
import { Wrapper } from './styles';
// import pdf from '../../Assets/../Assets/pdfsimple.pdf';

export function Announcement() {
  return (
    <Wrapper>
      <Container
        fluid
        className="about-section"
        style={{ textAlign: 'center' }}
      >
        <Container>
          <h1 className="project-heading">
            Parceiros Proj<strong className="purple">Ex </strong>
          </h1>

          <h1 className="project-heading">
            <strong className="purple">Editais</strong> vigentes
          </h1>
          <br></br>

          <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
            <Col xs={4} md={2} className="tech-icons">
              <img
                src="imagens/ifsul-logo.png"
                style={{ maxWidth: '200px' }}
                alt="avatar"
              />
              <br></br>
              <Button
                variant="primary"
                target="_blank"
                style={{ maxWidth: '150px' }}
                href="http://editais.ifsul.edu.br/index.php?c=lista&id=1891"
              >
                <AiOutlineDownload />
                &nbsp;Edital
              </Button>
              <br />
              <Button
                variant="primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfBBzFOAY94-WgZZuVlHOn3eukXtAi4Ld_cUaHwdjI0DUPorg/viewform"
                target="_blank"
                style={{ maxWidth: '150px', marginTop: '10px' }}
              >
                <AiOutlineDownload />
                &nbsp;Inscrição
              </Button>
            </Col>
          </Row>
          <br></br>
        </Container>
      </Container>
      <Footer/>
    </Wrapper>
  );
}
