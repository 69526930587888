import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavBar as Navbar } from './components/Navbar';
import { Announcement } from './pages/Announcement';
import { Download } from './pages/Downloads';
import { Home } from './pages/Home';
import { Portfolio } from './pages/Portfolio';
import { PorfolioInfo } from './pages/PortfolioInfo';
import { Team } from './pages/Team';

export const RoutesComponent = () => {
  const routes = [
    { path: '/', page: <Home /> },
    { path: '/team', page: <Team /> },
    { path: '/editais', page: <Announcement /> },
    { path: '/portfolio', page: <Portfolio /> },
    { path: '/downloads', page: <Download /> },
    { path: '/portfolio/:id', page: <PorfolioInfo /> },
  ];

  return (
    <Router>
      <Navbar />
      <Routes>
        {routes.map(({ path, page }, index) => (
          <Route key={index} path={path} element={page}></Route>
        ))}
      </Routes>
    </Router>
  );
};
