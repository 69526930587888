import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { AiOutlineDownload } from 'react-icons/ai';
import { Footer } from '../../components/Footer';
import { Wrapper } from './styles';

export function Download() {
  return (
    <Wrapper>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container fluid className="resume-section">
        <Row style={{ justifyContent: 'center', position: 'relative' }}>
          <Button
            variant="primary"
            href="https://firebasestorage.googleapis.com/v0/b/projex-7932a.appspot.com/o/internal%2Flivro-projex.pdf?alt=media&token=88251aa1-c13f-425e-acc5-07df7299eec4"
            target="_blank"
            style={{
              maxWidth: '500px',
              padding: '1.5rem 4rem',
              fontSize: '1.5rem',
            }}
          >
            <AiOutlineDownload />
            &nbsp;Download livro
          </Button>
        </Row>

        <Row className="resume"></Row>

        <Row style={{ justifyContent: 'center', position: 'relative' }}>
          <Button
            variant="primary"
            href="https://firebasestorage.googleapis.com/v0/b/projex-7932a.appspot.com/o/internal%2Fplanilha-projex.xlsx?alt=media&token=79db3dff-6648-47bc-b235-ccf38e992215https://firebasestorage.googleapis.com/v0/b/projex-7932a.appspot.com/o/internal%2Fplanilha-projex.xlsx?alt=media&token=79db3dff-6648-47bc-b235-ccf38e992215"
            target="_blank"
            style={{
              maxWidth: '500px',
              padding: '1.5rem 4rem',
              fontSize: '1.5rem',
            }}
          >
            <AiOutlineDownload />
            &nbsp;Download planilha
          </Button>
        </Row>

        <Row></Row>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Container>
      <Footer/>
    </Wrapper>
  );
}
