import styled from 'styled-components';

export const Container = styled.div`
  padding: 17px 14px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;


  a {
    text-decoration: none;
  }
  /* width: 30%; */

  > h3 {
    font-weight: 700;
    color: #000000;
    margin-bottom: 14px;
  }

  > p {
    font-size: 14px;
    color: #8b8b8b;
  }

  > div {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
`;

export const Tag = styled.div`
  background: #5c3b82;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  > img {
    margin-right: 3px;
    padding-bottom: 2px;
  }
`;
