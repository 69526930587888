import { collection, getDocs, query } from 'firebase/firestore';
import { Area } from '../models/Area';
import db from './firebase';

const getAreas = async (): Promise<Area[]> => {
  const querySnapshot = await getDocs(query(collection(db, 'areas')));

  return querySnapshot.docs.map(
    (question) =>
      ({
        ...question.data(),
        id: question.id,
      } as Area)
  );
};

const AreaService = {
  getAreas,
};

export default AreaService;
