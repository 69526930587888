import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Project } from '../../models/Project';
import ProjectService from '../../service/projects';
import InputMask from 'react-input-mask';

import {
  ButtonInteresse,
  DescriptionText,
  DocButton,
  Dot,
  Member,
  ModalExtendend,
  MoreInfo,
  ProjectTeam,
  Separator,
  Tag,
} from './styles';
import { Container } from './styles';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row } from 'react-bootstrap';
import { Interest } from '../../models/Interest';
import InterestService from '../../service/interests';
import Swal from 'sweetalert2';
import YouTube, { YouTubeProps } from 'react-youtube';
import { FooterPortifolio } from '../../components/FooterPortifolio';

export const PorfolioInfo = () => {
  const [project, setProject] = useState<Project>();
  const [modalShow, setModalShow] = useState(false);
  const [videoId, setVideoId] = useState('');
  let { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      const response = await ProjectService.getProjectById(`${id}`);
      setProject(response);
      setVideoId(response.videoUrl.split('v=')[1]);
    };

    fetchProject();
  }, [id]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '442',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <Container>
        {project && (
          <>
            <h2>{project.name}</h2>
            <Separator />
            <div className="tags">
              <Tag>
                <img src={`/icons/school.svg`} alt={`Icone de school`} />
                {project.instituition}
              </Tag>
              <Tag>
                <img src={`/icons/build.svg`} alt={`Icone de build`} />
                {project.sector}
              </Tag>
              <Tag>
                <img src={`/icons/book.svg`} alt={`Icone de book`} />
                {project.area}
              </Tag>
            </div>
            <div>
              <DescriptionText>
                <span className="label">
                  Problema Científico Socialmente Relevante:{' '}
                </span>
                {project.problem}
              </DescriptionText>
              <DescriptionText>
                <span className="label">
                  Solução Tecnológica Digital Inovadora:{' '}
                </span>
                {project.solution}
              </DescriptionText>
              <DescriptionText>
                <span className="label">Propósito Transformador Massivo: </span>
                {project.purpose}
              </DescriptionText>
            </div>
            <MoreInfo>
              <div className="left">
                {project.files.map((file, index) => (
                  <a
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '14px',
                      textDecoration: 'none',
                    }}
                    href={file.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <DocButton key={index}>
                      <img src="/icons/docs.svg" alt="Doc icon" />
                    </DocButton>
                    <p style={{ color: '#CE87FF' }}>{file.name}</p>
                  </a>
                ))}
              </div>
              <div className="right">
                <div className="button" onClick={() => setModalShow(true)}>
                  Tenho interesse 🤝
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#5C3B82',
                  }}
                >
                  💡 Nossa equipe irá entrar em contato
                </p>
              </div>
            </MoreInfo>
            <ModalForm
              show={modalShow}
              onHide={() => setModalShow(false)}
              project={project.name}
            />
          </>
        )}
      </Container>
      <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />;
      <Container>
        <ProjectTeam>
          {project?.team.map((member, index) => {
            if (project?.team[index - 1]?.coordinator) {
              return (
                <>
                  <Dot />
                  {/* <Member>
                    <h3>Membros</h3>
                    <div className="square">
                      <img
                        src={member.photo ?? '/icons/no-photo.svg'}
                        alt="Foto do membro"
                        onError={({ currentTarget }) =>
                          (currentTarget.src = '/icons/no-photo.svg')
                        }
                      />
                      <p>{member.name}</p>
                      <div>
                        {member.integra && (
                          <a
                            href={member.integra}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/icons/integra.svg"
                              alt="Icone do integra"
                            />
                          </a>
                        )}
                        {member.linkedin && (
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/icons/linkedin.svg"
                              alt="Icone do Linkedin"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </Member> */}
                </>
              );
            }

            return (
              <Member>
                <h3>{member.coordinator ? 'Coordenador' : 'Membro'}</h3>
                <div className="square">
                  <img
                    src={member.photo ?? '/icons/no-photo.svg'}
                    alt="Foto do membro"
                    onError={({ currentTarget }) =>
                      (currentTarget.src = '/icons/no-photo.svg')
                    }
                  />
                  <p>{member.name}</p>
                  <div>
                    {member.integra && (
                      <a href={member.integra} target="_blank" rel="noreferrer">
                        <img src="/icons/integra.svg" alt="Icone do integra" />
                      </a>
                    )}
                    {member.linkedin && (
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/icons/linkedin.svg"
                          alt="Icone do Linkedin"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </Member>
            );
          })}
        </ProjectTeam>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '75px',
          }}
        >
          <ButtonInteresse onClick={() => setModalShow(true)}>
            Tenho interesse 🤝
          </ButtonInteresse>
        </div>
      </Container>
      <FooterPortifolio/>
    </>
  );
};

interface ModalProps {
  onHide: () => void;
  show?: boolean;
  project: string;
}

function ModalForm(props: ModalProps) {
  const [formData, setFormData] = useState<Interest>({} as Interest);

  const sendForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await InterestService.create({
        ...formData,
        project: props.project,
        timestamp: new Date(),
      });

      Swal.fire('Obrigado! Em breve entraremos em contato').then(() =>
        props.onHide()
      );
    } catch (e) {
      Swal.fire({
        icon: 'error',
        text: 'Ocorreu algum erro! Tente novamente',
      });
    }
  };

  return (
    <ModalExtendend
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ padding: '27px 27px' }}>
        <h4
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '700',
            textAlign: 'center',
          }}
        >
          Está interessado? Nós te chamamos 💬
        </h4>
        <form onSubmit={(e) => sendForm(e)}>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="phone">Telefone</label>
                <InputMask
                  id="phone"
                  mask="(99) 99999-9999"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="company">Nome da Empresa</label>
                <input
                  type="text"
                  id="company"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="cnpj">CNPJ</label>
                <InputMask
                  type="text"
                  id="cnpj"
                  mask="99.999.999/9999-99"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, cnpj: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <label htmlFor="description">O que você faz?</label>
                <textarea
                  id="description"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                ></textarea>
              </div>
            </Col>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: 'center',
                  position: 'relative',
                  padding: '10px',
                }}
              >
                <Button
                  variant="primary"
                  target="_blank"
                  type="submit"
                  as="button"
                >
                  Salvar
                </Button>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </ModalExtendend>
  );
}
