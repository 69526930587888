import { Container, Row, Col, Button } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';
import Tilt from 'react-parallax-tilt';
import { Wrapper } from './styles';
import { AiFillInstagram } from 'react-icons/ai';
import { RiWhatsappFill } from 'react-icons/ri';

import { FaLinkedinIn } from 'react-icons/fa';
import { useState } from 'react';
import { Footer } from '../../components/Footer';

export function Home() {
  const [showTip, setShowTip] = useState<boolean>(false);

  return (
    <Wrapper>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={8} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Proj<strong className="main-name">Ex </strong>
              </h1>

              <h2 className="heading-name">
                <strong className="main-name"> Pesquisador</strong>
                <span className="wave" role="img" aria-labelledby="wave">
                  🚀
                </span>{' '}
                transforme seus{' '}
              </h2>

              <div
                style={{
                  padding: 50,
                  textAlign: 'left',
                  fontSize: '1.3em',
                  minHeight: '150px',
                }}
              >
                <Typewriter
                  options={{
                    strings: [
                      'Projetos de pesquisa',
                      'Projetos de extensão',
                      'Projetos Indissociáveis ',
                      'Projetos Científicos',
                      'Artigos Científicos',
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                  }}
                />
              </div>
              <h2
                style={{ paddingBottom: 50, fontSize: '1px' }}
                className="heading"
              >
                Em uma Solução Tecnológica Digital Inovadora{' '}
              </h2>
            </Col>

            <Col
              md={4}
              style={{
                paddingBottom: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/imagens/home.png"
                alt="home pic"
                style={{ maxHeight: '350px' }}
              />
            </Col>

            <br></br>
          </Row>
        </Container>
      </Container>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Row style={{ justifyContent: 'center', position: 'relative' }}>
              <Button
                variant="primary"
                href="/editais"
                target="_blank"
                style={{
                  maxWidth: '400px',
                  padding: '1.5rem 3rem',
                  fontSize: '1.5rem',
                }}
              >
                Editais Vigentes
              </Button>
            </Row>

            <Col md={7} className="home-about-description">
              <h1 style={{ fontSize: '42px' }}>
                <span className="purple3">O que é o ProjEx?</span>
              </h1>
              <p className="home-about-body">
                {/* //   ' ProjEx ou Projetos E  xponencias é um Programa de Transformação Digital de <b style="color: purple">Projetos acadêmicos,de pesquisa aplicada e/ou de extensão universitária, oriundos de instituições de educação profissional e tecnológica e/ou de educação superior</b> que visa apoiar pesquisadores e extensionistas no desenvolvimento de Soluções Tecnológicas Digitais Inovadoras aplicadas à Problemas Científicos Socialmente Relevantes investigados!'; */}
                <div
                  style={{ lineHeight: '28px' }}
                  onClick={() => setShowTip(true)}
                  onMouseLeave={() => setTimeout(() => setShowTip(false), 500)}
                >
                  {!showTip && (
                    <span>
                      ProjEx ou Projetos Exponencias é um Programa online de{' '}
                      <b
                        className="purple2"
                        style={{ color: 'purple', fontWeight: 'bold' }}
                      >
                        Transformação Digital de Projetos*{' '}
                      </b>
                      que visa apoiar pesquisadores no desenvolvimento de
                      Soluções Tecnológicas Digitais Inovadoras a Problemas
                      Científicos Socialmente Relevantes investigados em
                      pesquisas aplicadas.
                    </span>
                  )}
                  {showTip && (
                    <span>
                      ProjEx ou Projetos E xponencias é um Programa de
                      Transformação Digital de{' '}
                      <b style={{ color: 'purple', fontWeight: 'bold' }}>
                        Projetos acadêmicos,de pesquisa aplicada e/ou de
                        extensão universitária, oriundos de instituições de
                        educação profissional e tecnológica e/ou de educação
                        superior
                      </b>{' '}
                      que visa apoiar pesquisadores e extensionistas no
                      desenvolvimento de Soluções Tecnológicas Digitais
                      Inovadoras aplicadas à Problemas Científicos Socialmente
                      Relevantes investigados!'
                    </span>
                  )}
                </div>

                <br />
                <br />
              </p>
            </Col>
            <Col
              md={3}
              className="myAvtar"
              style={{ display: 'flex', marginLeft: '45px' }}
            >
              <Tilt>
                <img
                  src="/imagens/avatar.png"
                  alt="avatar"
                  style={{ maxHeight: '350px' }}
                />
              </Tilt>
            </Col>
            <Col md={12} className="home-about-description">
              <h1 style={{ fontSize: '42px' }}>
                <span className="purple3">Como funciona? </span>
              </h1>
              <div className="home-about-body">
                <p style={{ textAlign: 'center' }}>
                  A partir da adesão da instituição de ensino ao ProjEx, o
                  programa é constituído de 6 fases:
                </p>
                <br></br>
                <br></br>
                <main>
                  <ul className="produtos">
                    <li>
                      <img
                        src="/imagens/persons.svg"
                        className="pequeno"
                        alt="1"
                      />
                      <p className="produto-descricao">
                        1) Cadastro de Pesquisador (e equipe) p/ participação no
                        ProjEx.
                      </p>
                    </li>

                    <li>
                      <img
                        src="/imagens/device.svg"
                        className="grande"
                        alt="imagens"
                      />
                      <p className="produto-descricao">
                        2) curso online de formação teórica p/ Transformação
                        Digital de Projetos
                      </p>
                    </li>

                    <li>
                      <img
                        src="/imagens/brainstorm.svg"
                        className="grande"
                        alt="imagens"
                      />
                      <p className="produto-descricao">
                        3) Oficinas de brainstorm p/ geração de propostas de
                        Projetos Exponenciais
                      </p>
                    </li>
                    <li>
                      <img
                        src="/imagens/divulgar.svg"
                        className="maispequeno"
                        alt="imagens"
                      />
                      <p className="produto-descricao">
                        4) Divulgação das propostas geradas à comunidade externa
                      </p>
                    </li>

                    <li>
                      <img
                        src="/imagens/match.svg"
                        className="match"
                        alt="imagens"
                      />
                      <p className="produto-descricao">
                        5) Evento de Conexão c/ organizações externas* p/
                        obtenção de "matchs"
                      </p>
                    </li>

                    <li>
                      <img
                        src="/imagens/work.svg"
                        className="pequeno"
                        alt="imagens"
                      />
                      <p className="produto-descricao">
                        6) Apoio Estratégico* as propostas com match
                      </p>
                    </li>
                  </ul>
                </main>
                <br></br>
              </div>

              <h1 style={{ fontSize: '42px' }}>
                <span className="purple3">Quanto Custa? </span>
              </h1>
              <p className="home-about-body" style={{ textAlign: 'center' }}>
                <span className="purple3">
                  <span className="whiteBold">
                  {' '}
                  É totalmente gratuito!!!{' '}
                  </span>
                  <span className="wave" role="img" aria-labelledby="wave">
                    🚀
                  </span>
                </span>
                <br></br>
                <br></br>
              </p>

              <h1 style={{ fontSize: '42px' }}>
                <span className="purple3">Bora participar? </span>
              </h1>
              <p className="home-about-body" style={{ textAlign: 'center' }}>
                Veja se a sua instituição já aderiu ao ProjEx e faça a sua
                inscrição!
                <br></br>
                <br></br>
              </p>
              <br></br>
              <h1 style={{ fontSize: '42px' }}>
                <span className="purple3">
                  Sua instituição ainda não aderiu ao ProjEx?{' '}
                </span>
              </h1>
              <br></br>
              <p className="home-about-body" style={{ textAlign: 'center' }}>
                Seja um embaixador ProjEx e leve o programa para seu espaço
                educacional!
              </p>
              <p className="home-about-body" style={{ textAlign: 'center' }}>
                Preencha seu cadastro em nossa lista de Embaixadores ProjEx e
                aguarde nosso contato!
              </p>
              <br></br>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <br></br>
              <Row
                style={{
                  justifyContent: 'center',
                  position: 'relative',
                  marginBottom: '15px',
                }}
              >
                <Button
                  variant="primary"
                  size="lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfku5TMLRJ6t6wtIhqDisYD58_gHl8JuqwuyPP3QO7Imu9bog/viewform"
                  target="_blank"
                  style={{
                    maxWidth: '600px',
                    padding: '1.5rem 4rem',
                    fontSize: '1.5rem',
                  }}
                >
                  Seja um embaixador ProjEx
                </Button>
              </Row>

              <br></br>
              <p style={{ marginBottom: '15px' }}>
                Nossas <span className="purple">redes sociais </span>
              </p>

              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://whatsapp.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <RiWhatsappFill />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/company/projex-ifrs/about/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/projexifrs/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer/>
    </Wrapper>
  );
}
