import { collection, getDocs, query } from 'firebase/firestore';
import { Sector } from '../models/Sector';
import db from './firebase';

const getSectors = async (): Promise<Sector[]> => {
  const querySnapshot = await getDocs(query(collection(db, 'sectors')));

  return querySnapshot.docs.map(
    (question) =>
      ({
        ...question.data(),
        id: question.id,
      } as Sector)
  );
};

const SectorService = {
  getSectors,
};

export default SectorService;
