import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const Container = styled.section`
  margin: 45px auto 0 auto;
  max-width: 1280px;
  padding: 2rem 118px;
  margin-bottom: 50px;

  @media (max-width: 700px) {
    padding: 2rem 3rem;
  }

  > h2 {
    font-size: 36px;
    font-weight: 600px;
    text-align: center;
    margin-top: 20px;
  }

  .tags {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;

    @media (max-width: 700px) {
      justify-content: center;
  }
  }
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: #5c3b82;
  margin: 45px 0 30px 0;
`;

export const Tag = styled.div`
  background: #5c3b82;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  > img {
    margin-right: 3px;
    padding-bottom: 2px;
  }
`;

export const DescriptionText = styled.div`
  font-size: 20px;
  line-height: 37px;
  font-weight: 500;
  margin-top: 38px;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 18px;
  }

  &:first-child {
    margin-top: 58px;
  }
  color: #e1e1e1;
  > .label {
    font-weight: 800;
    text-decoration: underline;
    color: #fff;

    @media (max-width: 1000px) {
      font-size: 20px;
      display: block;
    }
  }
`;

export const MoreInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 90px;

  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .left {
    display: flex;
    justify-content: center;
    gap: 52px;
    margin-bottom: 50px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 17px;
    > .button {
      background: #d64a4a;
      border-radius: 5px;
      padding: 1.5rem 3rem;
      color: #ffffff;
      font-size: 2rem;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 1.5rem;
      }

      outline: none;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
`;

export const DocButton = styled.button`
  width: 100px;
  height: 100px;
  background: transparent;
  border: solid 1px #ce87ff;
  border-radius: 5px;
`;

export const ModalExtendend = styled(Modal)`
  div[class*='col'] {
    margin-top: 20px;
  }

  label {
    color: black;
    margin-bottom: 5px;
    margin-left: 2px;
  }

  input,
  textarea {
    background: rgba(120, 120, 120, 0.13);

    &:focus {
      background: rgba(120, 120, 120, 0.13);
      box-shadow: none;
      border-color: #5c3b82;
    }
  }
`;

export const ProjectTeam = styled.div`
  display: flex;
  align-items: center;
  gap: 44px;
  margin-bottom: 65px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Member = styled.div`
  > h3 {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .square {
    padding: 8px 10px;
    width: 200px;
    height: 280px;
    border: 1px solid #bb78e9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    > img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    > p {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    div {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }
`;

export const Dot = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #5c3b82;
  margin-top: 1.5rem;
`;

export const ButtonInteresse = styled.div`
  background: #d64a4a;
  border-radius: 5px;
  padding: 1.5rem 3rem;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;
