import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 0.8rem;
  }

  .footer {
    background-color: #0a0416 !important;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
  }
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    z-index: 1;
    text-align: center !important;
  }

  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }

    .footer-body {
      text-align: center !important;
    }
  }

  .footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }
`;
