import { Container, LabelContainer, SelectField } from './styles';

interface SelectProps {
  label?: string;
  icon?: string;
  children?: React.ReactNode;
  onChange: (value: string) => void;
}

export const Select = ({ label, icon, children, onChange }: SelectProps) => {
  return (
    <Container>
      {label && (
        <LabelContainer>
          {icon && <img src={`icons/${icon}.svg`} alt={`Icone de ${label}`} />}
          <label htmlFor="">{label}</label>
        </LabelContainer>
      )}
      <SelectField onChange={(e) => onChange(e.target.value)}>
        <option value="" id="">
          Selecione
        </option>
        {children}
      </SelectField>
    </Container>
  );
};
