//   const routes = [
//     { name: 'Home', route: '/' },
//     { name: 'Equipe', route: 'team' },
//     { name: 'Editais Vigentes', route: '/announcement' },
//     { name: 'Portfolio', route: '/portfolio' },
//     { name: 'Downloads', route: '/downloads' },
//   ];

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import { NavLink as Link } from 'react-router-dom';

import { ImBlog } from 'react-icons/im';
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from 'react-icons/ai';

import { MdOutlineSchool } from 'react-icons/md';
import { CgFileDocument } from 'react-icons/cg';
import { useState } from 'react';
import { Wrapper } from './styles';

export function NavBar() {
  const [expand, updateExpanded] = useState<boolean>(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener('scroll', scrollHandler);

  return (
    <Wrapper>
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={navColour ? 'sticky' : 'navbar'}
      >
        <Container>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(!expand);
            }}
          ></Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ justifyContent: 'center' }}
          >
            <Nav defaultActiveKey="/">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineHome style={{ marginBottom: '2px' }} /> Home
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/team"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineUser style={{ marginBottom: '2px' }} /> Equipe
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/editais"
                  onClick={() => updateExpanded(false)}
                >
                  <MdOutlineSchool style={{ marginBottom: '2px' }} /> Editais
                  vigentes
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to="/portfolio">
                  <AiOutlineFundProjectionScreen
                    style={{ marginBottom: '2px' }}
                  />{' '}
                  Portfolio
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/downloads"
                  onClick={() => updateExpanded(false)}
                >
                  <CgFileDocument style={{ marginBottom: '2px' }} /> Downloads
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://tangerine-tapioca-a05355.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImBlog style={{ marginBottom: '2px' }} /> Blog
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="fork-btn"></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Wrapper>
  );
}
