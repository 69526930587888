import styled from 'styled-components';

export const Wrapper = styled.section`
  /* padding: 2rem 118px;
  max-width: 1280px; */
  /* margin-left: auto;
  margin-right: auto; */

  /* display: flex;
  flex-direction: column;
  align-items: center; */

  .home-header {
    padding-top: 80px !important;
  }

  .home-section {
    position: relative;
    z-index: -1;
    /* background-image: linear-gradient(
        to bottom left,
        rgba(17, 16, 16, 0.678),
        rgba(12, 10, 22, 0.863)
      ),
      url('/imagens/home-bg.jpg'); */
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  .home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
  }

  .heading {
    font-size: 2.1em !important;
    padding-left: 50px !important;
  }

  .heading-name {
    font-size: 2em !important;
    padding-left: 45px !important;
  }

  .main-name {
    font-size: 1em !important;

    color: #cd5ff8;
  }

  .Typewriter__wrapper {
    font-size: 2.2em !important;
    color: #be6adf !important;
    font-weight: 600 !important;
  }
  .Typewriter__cursor {
    font-size: 2.4em !important;
    color: #b562d6 !important;
  }

  @media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em !important;
      font-weight: 500 !important;
      position: absolute !important;
    }
    .Typewriter__cursor {
      display: none !important;
    }
  }

  .home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }

  .home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
  }

  .home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
  }

  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }

  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }

  .home-social-icons::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }

  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
  }

  .home-social-icons:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
  }

  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }

  .icon-colour {
    color: #700c86 !important;
  }
  .produtos {
    width: 940px;
    margin: 0 auto;
    padding: 50px 0;
  }
  .maispequeno {
    width: 60%;
    margin-bottom: 10%;
  }
  .pequeno {
    width: 50%;
    min-height: 10%;
    max-height: 20%;
    margin-bottom: 10%;
  }

  .grande {
    width: 40%;
    margin-bottom: 10%;
  }
  .match {
    width: 30%;
    margin-bottom: 10%;
  }

  .produtos li {
    display: inline-block;
    text-align: center;
    width: 30%;
    vertical-align: top;
    margin: 0 1.5%;
    padding: 30px 20px;
    box-sizing: border-box;
  }

  .produtos li:hover {
    border-color: #c78c19;
  }
  .produtos li:hover h2 {
    font-size: 34px;
  }

  .produtos li:active {
    border-color: forestgreen;
  }

  .produtos h2 {
    font-size: 30px;
    font-weight: bold;
  }

  .produto-descricao {
    font-size: 18px;
    padding: 10%;
    border-right: 2px solid #b668d7;
  }

  @media screen and (max-width: 480px) {
    .caixa,
    .principal,
    .conteudo-beneficios,
    .mapa-conteudo,
    .video,
    .produtos {
      width: auto;
    }
    .lista-beneficios,
    .imagem-beneficios {
      width: 100%;
    }

    .produtos li {
      width: 100%;
      margin: 4px auto;
    }
  }
  .purple3 {
    color: #c770f0 !important;
  }
  .whiteBold {
    color: #ffffff;
    font-weight: bold;
  }

  .home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
    margin-bottom: 100px;
  }

  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }

  .home-social-icons::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }

  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
  }

  .home-social-icons:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
  }

  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }

  .icon-colour {
    color: #700c86 !important;
  }
`;
