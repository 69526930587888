import styled from 'styled-components';

export const Container = styled.section`
  padding: 2rem 118px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 380px) {
    padding: 2rem 3rem;
  }

  .list{
    display: flex;
    flex-direction: column;
  }

  > div {
    width: 100%;
    margin-top: 6rem;
    display: flex;
    justify-content: center;

    @media (min-width: 380px) {
      margin-top: 4rem;
    }

    > p {
      width: 70%;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.8;

      @media (max-width: 700px) {
        width: 100%;

      }
    }
  }
`;

export const PortfolioList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  margin-top: 35px;
  justify-content: space-between;
  row-gap: 30px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Filter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: #5c3b82;
  margin: 35px 0 15px 0;
`;
