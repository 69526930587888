import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { Project } from '../models/Project';
import db from './firebase';

const getProjects = async (): Promise<Project[]> => {
  const querySnapshot = await getDocs(query(collection(db, 'projects')));
  return querySnapshot.docs.map(
    (question) =>
      ({
        ...question.data(),
        id: question.id,
      } as Project)
  );
};

const getProjectById = async (id: string): Promise<Project> => {
  const query = await getDoc(doc(db, 'projects', id ))
  return {
    ...query.data(),
    id,
  } as Project;
};

const ProjectService = {
  getProjects,
  getProjectById,
};

export default ProjectService;
