import { addDoc, collection } from 'firebase/firestore';
import { Interest } from '../models/Interest';
import db from './firebase';

const create = async (interest: Interest) => {
  return await addDoc(collection(db, 'interests'), interest);
};

const InterestService = {
  create,
};

export default InterestService;
