import { collection, getDocs, query } from 'firebase/firestore';
import { Instituition } from '../models/Instituition';
import db from './firebase';

const getInstituitions = async (): Promise<Instituition[]> => {
  const querySnapshot = await getDocs(query(collection(db, 'instituitions')));

  return querySnapshot.docs.map(
    (question) =>
      ({
        ...question.data(),
        id: question.id,
      } as Instituition)
  );
};

const InstituitionService = {
  getInstituitions,
};

export default InstituitionService;
