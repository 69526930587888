import { useNavigate } from 'react-router-dom';
import { Project } from '../../models/Project';
import { Container, Tag } from './styles';

interface CardPortfolioProps {
  project: Project;
}

export const CardPortfolio = ({ project }: CardPortfolioProps) => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(project.id)}>
      <h3>{project.name}</h3>

      <p>{project.shortDescription}</p>

      <div>
        <Tag>
          <img src={`icons/school.svg`} alt={`Icone de school`} />
          {project.instituition}
        </Tag>
        <Tag>
          <img src={`icons/build.svg`} alt={`Icone de build`} />
          {project.sector}
        </Tag>
        <Tag>
          <img src={`icons/book.svg`} alt={`Icone de book`} />
          {project.area}
        </Tag>
      </div>
    </Container>
  );
};
