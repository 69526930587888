import { useCallback, useEffect, useState } from "react";
import { CardPortfolio } from "../../components/CardPortfolio";
import { Select } from "../../components/Select";
import { Area } from "../../models/Area";
import { Instituition } from "../../models/Instituition";
import { Project } from "../../models/Project";
import { Sector } from "../../models/Sector";
import AreaService from "../../service/areas";
import InstituitionService from "../../service/instituitions";
import ProjectService from "../../service/projects";
import SectorService from "../../service/sectors";
import Swal from "sweetalert2";
import { Container, Separator, PortfolioList, Filter } from "./styles";
import { FooterPortifolio } from "../../components/FooterPortifolio";


export const Portfolio = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [instituitions, setInstituitions] = useState<Instituition[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);

  const [filterInstituition, setFilterInstituition] = useState("");
  const [filterSector, setFilterSector] = useState("");
  const [filterArea, setFilterArea] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      Swal.fire({
        title: '<strong>Olá 😊</strong>',
        icon: 'info',
        html:
          'Os Projetos Exponenciais apresentados são exemplos fictícios para ilustração dos resultados a serem obtidos' 
      })
      const responseProjects = await ProjectService.getProjects();
      const responseInstituitions =
        await InstituitionService.getInstituitions();
      const responseSectors = await SectorService.getSectors();
      const responseAreas = await AreaService.getAreas();
      setInstituitions(responseInstituitions);
      setProjects(responseProjects);
      setSectors(responseSectors);
      setAreas(responseAreas);
    };

    fetchProjects();
  }, []);

  const projectsFiltereds = useCallback(
    () =>
      projects.filter(({ area, sector, instituition }) => {
        let filterValue = true;
        if (filterArea) {
          filterValue = filterValue && area === filterArea;
        }
        if (filterInstituition) {
          filterValue = filterValue && instituition === filterInstituition;
        }
        if (filterSector) {
          filterValue = filterValue && sector === filterSector;
        }

        return filterValue;
      }),
    [projects, filterInstituition, filterSector, filterArea]
  )();

  return (
    <Container>
      <div>
        <p>
          Nosso portfolio de Projetos Exponenciais está cheio de boas
          iniciativas que buscam apoio para decolar! Vamos fechar esta parceria.
          🤝
        </p>
      </div>
      <div
        style={{
          width: `100%`,
          display: "flex",
          flexDirection: `column`,
        }}
      >
        <Filter>
          <Select
            label="Instituiçao"
            icon="school"
            onChange={(e) => setFilterInstituition(e)}
          >
            {instituitions.map((r) => (
              <option key={r.name}>{r.name}</option>
            ))}
          </Select>
          <Select
            label="Setor"
            icon="build"
            onChange={(e) => setFilterSector(e)}
          >
            {sectors.map((r) => (
              <option key={r.name}>{r.name}</option>
            ))}
          </Select>
          <Select label="Área" icon="book" onChange={(e) => setFilterArea(e)}>
            {areas.map((r) => (
              <option key={r.name}>{r.name}</option>
            ))}
          </Select>
        </Filter>
        <div
          style={{
            width: `100%`,
          }}
        >
          <Separator />
          <p style={{ textAlign: "center", color: "#5C3B82" }}>
            💡clique no card para mais detalhes do projeto
          </p>
        </div>
        <PortfolioList>
          {projectsFiltereds.map((project) => (
            <CardPortfolio key={project.id} project={project} />
          ))}
        </PortfolioList>
      </div>
      <FooterPortifolio/>
    </Container>
  );
};
