import { Container, Row, Col } from 'react-bootstrap';
import { AiFillInstagram } from 'react-icons/ai';
import { SiWhatsapp } from 'react-icons/si';
import { FaLinkedinIn } from 'react-icons/fa';
import { Wrapper } from './styles';

export function FooterPortifolio() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Wrapper>
      <Container fluid className="footer">
        <Row>
          <Col md="4" className="footer-copywright">
            <h3>Projetos Exponenciais</h3>
          </Col>
          <Col md="4" className="footer-copywright">
            <h3>Copyright © {year} ProjEx. Powered by CXD.dev</h3>
          </Col>
          <Col md="4" className="footer-body">
            <ul className="footer-icons">
              <li className="social-icons"></li>
              <li className="social-icons">
                <a
                  href="https://whatsapp.com/"
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/projex-ifrs/about/"
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/projexifrs/"
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
