import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 900px) {
    width: 100%;
    font-size: 1.1rem;
  }
`;

export const LabelContainer = styled.div`
  margin-bottom: 10px;

  @media (max-width: 900px) {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  > img {
    margin-right: 10px;
  }
`;

export const SelectField = styled.select`
  width: 100%;
  background: rgba(120, 120, 120, 0.13);
  border: 1px solid #bb78e9;
  border-radius: 5px;
  height: 35px;
  color: #5c3b82;
  padding-left: 1rem;

  &:focus {
    outline: none;
  }
`;
