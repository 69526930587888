// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBVuwHD2dX5vEx4n7kpDXtj4_pcZNs9GoY",
  authDomain: "projex-7932a.firebaseapp.com",
  projectId: "projex-7932a",
  storageBucket: "projex-7932a.appspot.com",
  messagingSenderId: "97648358824",
  appId: "1:97648358824:web:6ec790d618bf8ad180199d",
  measurementId: "G-1HVV0R8GG2"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;