import styled from 'styled-components';

export const Wrapper = styled.div`

  @media (min-width: 380px) {
    padding: 0rem 2rem;
  }

  .resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }

  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
  }
`;
