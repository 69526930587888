import Particle from './components/Particles';
import { RoutesComponent } from './routes';
import { GlobalCss } from './styles/globals';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <GlobalCss />
      <Particle />
      <RoutesComponent />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
